import React, { useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import poshtraining from '../assets/img/poshtraining.webp';
import poshtraining2 from '../assets/img/poshtraining2.webp';
import poshtraining3 from '../assets/img/poshtraining3.webp';
import poshtraining4 from '../assets/img/poshtraining4.webp';
import caroline1 from '../assets/img/caroline1.webp';
import caroline2 from '../assets/img/caroline2.webp';
import caroline3 from '../assets/img/caroline3.webp';
import caroline4 from '../assets/img/caroline4.webp';
import srp1 from '../assets/img/srp1.webp';
import srp2 from '../assets/img/srp2.webp';
import srp3 from '../assets/img/srp3.webp';
import srp4 from '../assets/img/srp4.webp';
import toone1 from '../assets/img/toone1.webp';
import toone2 from '../assets/img/toone2.webp';
import pb1 from '../assets/img/pb1.webp';
import pb2 from '../assets/img/pb2.webp';


const portfolioItems = [
  {
    name: 'Caroline Sandiford',
    description: 'Caroline is a qualified and accredited Legal Executive, specialising in Wills, Probate and Lasting Powers of Attorney.',
    link: 'https://carolinesandiford.uk/',
    images: [caroline1, caroline2, caroline3, caroline4],
  },
  {
    name: 'SRP Offroad LTD',
    description: 'SRP Offroad LTD is an online store, stocking all things Offroad Motorcycles',
    link: 'https://sandifordracingproducts.co.uk/',
    images: [srp1, srp2, srp3, srp4],
  },
  {
    name: 'PoshTraining',
    description: 'An online training platform for the beauty industry, offering AET & CAVA courses with flexible pricing, payment plans and a bespoke student portal.',
    link: 'https://www.poshtraining.co.uk/',
    images: [poshtraining, poshtraining2, poshtraining3, poshtraining4],
  },
  {
    name: 'Toone Skips',
    description: 'Toone Waste Management are experts in waste disposal and material cycling in Tyldesley, Greater Manchester.',
    link: 'https://toone-skips.co.uk/',
    images: [toone1,toone2],
  },

  {
    name: 'Pemberley Calligraphy',
    description:'"Joe set up my WordPress site in no time at all, handling all things technical in the background and allowing me to focus on the design. The whole process was seamless and I highly recommend working with him."',
    link: 'https://pemberleycalligraphy.co.uk/',
    images: [pb1,pb2],
  }
];

const PortfolioSection = () => {
  return (
    <div className="portfolio-section bg-dark" id="portfolio-section">
      <Container>
        <Row>
          <Col>
            <h1 className="centered-text">My Portfolio</h1>
            <p className="centered-text">Here are some of the most recent projects I've worked on:</p>
          </Col>
        </Row>
        <Row>
          {portfolioItems.map((item, index) => (
            <Col md={6} xs={12} key={index} className="mb-4">

              <PortfolioItem item={item} />
              
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

const PortfolioItem = ({ item }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
    return (
      <a 
        href={item.link} 
        className="portfolio-item glow-on-hover" 
        onMouseEnter={() => {
          const intervalId = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % item.images.length);
          }, 1000);
          window.intervalId = intervalId;
        }}
        onMouseLeave={() => {
          clearInterval(window.intervalId);
        }}
      >
        <div 
          className="portfolio-item-background" 
          style={{ backgroundImage: `url(${item.images[currentImageIndex]})` }}
        ></div>
        <div className="portfolio-item-content">
          <h2>{item.name}</h2>
          <p>{item.description}</p>
          <br></br>
          <p>{item.testimonial}</p>
        </div>
      </a>
    );
  };
  

  

export default PortfolioSection;
